import React from "react";
import { Helmet } from "react-helmet";

const NotFound = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <Helmet>
        <title>Pagină negăsită | Vulcanizare ALEX BEST DRIVE</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="max-w-md w-full space-y-8 text-center">
        <div>
          <h1 className="text-9xl font-extrabold text-gray-400">404</h1>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Oops! Pagină negăsită
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Ne pare rău, dar pagina pe care o cauți nu există.
          </p>
        </div>
        <div>
          <a
            href="/"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-rose-600 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
          >
            Înapoi la pagina principală
          </a>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
