import { social } from "./Data/constants";
const footerNavigation = {
  vulcanizare: [
    { name: "Servicii", href: "/servicii" },
    { name: "Blog", href: "/blog" },
    { name: "Tarife", href: "/preturi" },
    { name: "Contact", href: "/contact" },
  ],
  // suport: [
  //   { name: 'Prețuri', href: '/preturi' },
  //   { name: 'Contact', href: '#' },
  //   { name: 'Întrebări Frecvente', href: '#' },
  // ],
  // companie: [
  //   { name: 'Despre Noi', href: '/despre' },
  //   { name: 'Blog', href: '/blog' },
  // ],
  // legal: [
  //   { name: 'Termeni și Condiții', href: '#' },
  //   { name: 'GDPR', href: '#' },
  // ],
};

const Footer = () => {
  return (
    <footer className="bg-gray-50" aria-labelledby="footer-heading">
      <div className="max-w-md mx-auto pt-8 px-4 sm:max-w-7xl sm:px-6 lg:pt-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-6 xl:col-span-1">
            <img
              className="h-14 w-auto"
              src={require("../assets/vulcanizare-alex-best-drive-logo-footer.png")}
              alt="Vulcanizare Alex Best Drive Logo Footer"
            />
            <p className="text-gray-500 text-base">
              Siguranță și confort pentru orice anvelopă.
            </p>
            <div className="flex space-x-6">
              {social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Vulcanizare
                </h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.vulcanizare.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              {/* <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Suport</h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.suport.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Companie</h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.companie.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div> */}
              {/* <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Legal</h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.legal.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>*/}
            </div>
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 py-8">
          <p className="text-base text-gray-400 xl:text-center">
            Construit de{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.mihaihlodec.com"
            >
              <u>mihaihlodec.com</u>
            </a>{" "}
            &copy; 2021-2025 Alex Best Drive SRL. Toate drepturile Rezervate.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
