import { Helmet } from "react-helmet";

export default function IncarcareAC() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <Helmet>
        <title>
          Încărcare Aer Condiționat cu freon | Vulcanizare ALEX BEST DRIVE
        </title>
        <link
          rel="canonical"
          href="https://www.vulcanizare-alexbestdrive.ro/blog/sfaturi-ingrijire-anvelope/"
        />
        <meta
          name="description"
          content="Cum să îngrijești anvelopele pentru o utilizare de durată? Află cele mai bune metode prin care anvelopele să reziste pentru mai mult timp."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charSet="UTF-8" />
      </Helmet>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Încărcarea aerului condiționat cu freon
            </span>
          </h1>
          {/* <div className="text-center mt-8">
            <p className="text-sm font-medium text-gray-900">
              <p className="hover:underline">
                Alexandru Buraciuc
              </p>
            </p>
            <div className="space-x-1 text-sm text-gray-500">
              <time dateTime='2022-03-16'>16 Martie, 2022</time>
              <span aria-hidden="true">&middot;</span>
              <span>3 min read</span>
            </div> */}
          {/* </div> */}
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Deobicei nu multă lume dă importanță aerului condiționat al unei
            mașini decat atunci când acesta nu mai funcționează corect, într-o
            zi toridă de vară. Un aer condiționat nefuncțional nu e doar un
            disconfort, cii poate cauza afecțiuni de sănătate mai ales atunci
            când nu funcționează pentru mai mult timp.
          </p>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <p>
            Dacă observi că sistemul de aer condiționat al mașinii tale nu
            funcționează corespunzător sau nu reușește să răcească interiorul
            mașinii, este necesar ca acesta să fie reîncărcat într-o locație
            autorizată. Reîncărcarea cu aer condiționat reprezintă procesul de
            adaugare a freonului în sistemul de AC pentru a reveni din nou la
            performanțele de fabrică.
          </p>
          <div className="relative w-fit mx-auto">
            <img
              className="w-full h-full object-fit"
              src={require("../../assets/vulcanizare-cisnadie-incarcare-ac-freon.png")}
              alt="Incarcare aer conditionat vulcanizare alex best drive cisnadie"
            />
          </div>
          <h2>Semne că sistemul de AC trebuie încărcat</h2>
          <ul>
            <li>
              Aerul condiționat se încălzește după câteva minute, în loc să
              continue răcirea
            </li>
            <li>Aerul condiționat nu răcește interiorul mașinii deloc</li>
            <li>Scurgeri vizibile ale freonului</li>
          </ul>
          <p>
            Ca să previi orice probleme legate de aerul condiționat, îți
            recomandăm să verifici întreg sistemul de aer condiționat cel puțin
            o dată pe an. Dacă este necesar, după verificări, aerul condiționat
            se încarcă doar cu freon original, de cea mai bună calitate, într-un
            service autorizat.
          </p>
          <p>
            Vulcanizarea Alex Best Drive din Cisnădie este autorizată să execute
            verificări și încărcări ale sistemului de aer condiționat. Poți să
            vezi prețurile aplicate <a href="/preturi">aici</a> sau să te
            programezi direct și simplu <a href="/contact">online</a>.
          </p>
        </div>
      </div>
    </div>
  );
}
