import React, { useState, useRef } from "react";
import {
  MailIcon,
  PhoneIcon,
  CalendarIcon,
  ClockIcon,
  TruckIcon,
  UserIcon,
  ChatAlt2Icon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
import { Helmet } from "react-helmet";
import "./contact.css";

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    carNumber: "",
    message: "",
    appointmentDate: "",
    appointmentTime: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [availableTimes, setAvailableTimes] = useState([]);
  const formRef = useRef(null);

  // Get tomorrow's date in YYYY-MM-DD format for min date
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowFormatted = tomorrow.toISOString().split("T")[0];

  // Get date 30 days from now for max date
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 30);
  const maxDateFormatted = maxDate.toISOString().split("T")[0];

  // Handle changes to form fields
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Special handling for date field to check for Sundays
    if (name === "appointmentDate" && value) {
      const date = new Date(value);
      if (date.getDay() === 0) {
        // 0 is Sunday
        setErrorMessage("Nu se pot face programări în zilele de Duminică");
        // Don't update the state for Sunday dates
        return;
      }

      // Update available times for valid dates
      updateAvailableTimes(value);
    }

    // Update form data for all fields
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Clear error message when user makes changes, except for Sunday date errors
    if (!(name === "appointmentDate" && errorMessage.includes("Duminică"))) {
      setErrorMessage("");
    }
  };

  // Update available times based on selected date
  const updateAvailableTimes = (dateString) => {
    const selectedDate = new Date(dateString);
    const dayOfWeek = selectedDate.getDay(); // 0 = Sunday, 6 = Saturday

    let timeOptions = [];

    // Sunday - No times available
    if (dayOfWeek === 0) {
      // Sunday - leave empty
    }
    // Saturday - Limited hours (8:00-13:00)
    else if (dayOfWeek === 6) {
      timeOptions = [
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
      ];
    }
    // Weekdays - Regular hours (8:00-17:00)
    else {
      timeOptions = [
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
      ];
    }

    setAvailableTimes(timeOptions);

    // Clear appointment time if date changes and the previously selected time is no longer available
    if (
      formData.appointmentTime &&
      !timeOptions.includes(formData.appointmentTime)
    ) {
      setFormData((prev) => ({
        ...prev,
        appointmentTime: "",
      }));
    }
  };

  // Validate form fields
  const validateForm = () => {
    // Check if Sunday is selected
    if (formData.appointmentDate) {
      const date = new Date(formData.appointmentDate);
      if (date.getDay() === 0) {
        // 0 is Sunday
        setErrorMessage("Nu se pot face programări în zilele de Duminică");
        return false;
      }
    }

    // Romanian phone number validation
    const phoneRegex =
      /^(07[0-8]{1}[0-9]{7}|02[0-9]{1}[0-9]{7}|03[0-9]{1}[0-9]{7})$/;

    if (!formData.phone || formData.phone.trim() === "") {
      setErrorMessage("Numărul de telefon este obligatoriu");
      return false;
    }

    if (!phoneRegex.test(formData.phone)) {
      setErrorMessage("Numărul de telefon nu este valid");
      return false;
    }

    // Validate appointment date and time
    if (!formData.appointmentDate) {
      setErrorMessage("Vă rugăm să selectați o dată pentru programare");
      return false;
    }

    if (!formData.appointmentTime) {
      setErrorMessage("Vă rugăm să selectați o oră pentru programare");
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");

    if (!validateForm()) {
      setIsSubmitting(false);
      return;
    }

    // Encode data for Netlify form submission
    const encode = (data) => {
      return Object.keys(data)
        .map(
          (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&");
    };

    // Submit the form data to Netlify
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        ...formData,
      }),
    })
      .then(() => {
        console.log("Form submitted successfully to Netlify");

        // Also trigger our function directly for immediate notification
        // This is a backup in case the Netlify form-to-function connection isn't working
        fetch("/.netlify/functions/form-handler", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            form_name: "contact",
            data: formData,
          }),
        })
          .then((response) => {
            console.log("Function triggered directly:", response.status);
          })
          .catch((functionError) => {
            console.error("Error triggering function:", functionError);
          });

        setIsSubmitting(false);
        setIsSubmitted(true);
        // Reset form
        formRef.current.reset();
        setFormData({
          name: "",
          phone: "",
          carNumber: "",
          message: "",
          appointmentDate: "",
          appointmentTime: "",
        });
        setAvailableTimes([]);
      })
      .catch((error) => {
        setIsSubmitting(false);
        setErrorMessage(
          "A apărut o eroare la trimitere. Vă rugăm să încercați mai târziu."
        );
        console.error("Form submission error:", error);
      });
  };

  // Format date in Romanian for display
  const formatDateRomanian = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("ro-RO", options).toUpperCase();
  };

  return (
    <div id="contact">
      <Helmet>
        <title>
          Contact Vulcanizare | Vulcanizare ALEX BEST DRIVE din Sibiu, Cisnadie
        </title>
        <link
          rel="canonical"
          href="https://www.vulcanizare-alexbestdrive.ro/contact"
        />
        <meta
          name="description"
          content="Servicii vulcanizare premium: echilibrare, dejantare, reparații anvelope, pene, schimb ulei și multe altele."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charSet="UTF-8" />
        {/* Add CSS to localize date input */}
        <style>
          {`
          /* Force the calendar to use Romanian locale */
          input[type="date"]::-webkit-calendar-picker-indicator {
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 10;
          }

          /* Some browsers will respect this */
          input[type="date"]::before {
            content: attr(data-placeholder);
            width: 100%;
          }
          
          input[type="date"]:valid::before {
            display: none;
          }
          
          /* Compact form styling */
          .label-with-icon {
            display: flex;
            align-items: center;
            gap: 0.5rem;
          }
          
          .form-group {
            margin-bottom: 0.75rem;
          }
          
          .form-group:last-child {
            margin-bottom: 0;
          }
          
          .compact-form {
            max-width: 100%;
          }

          /* Ensure date input is clickable across browsers */
          .date-input-container {
            position: relative;
            cursor: pointer;
          }
          
          /* Ensure the input itself is fully clickable */
          input[type="date"] {
            cursor: pointer;
            position: relative;
            z-index: 1;
          }
          `}
        </style>
      </Helmet>
      <div className="relative bg-white">
        <div className="absolute inset-0">
          <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
        </div>
        <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
          <div className="bg-gray-50 py-8 sm:py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-16 xl:pr-12">
            <div className="max-w-lg mx-auto">
              <h1 className="font-extrabold tracking-tight text-gray-900 text-4xl">
                Contact
              </h1>
              <h3 className="mt-3 text-lg leading-6 text-gray-500 font-normal">
                Vulcanizarea este situată la câteva minute de Sibiu, la intrarea
                în orașul Cisnadie. Ca și reper pentru localnici, zona fostei
                mori Panalim.
              </h3>
              <dl className="mt-8 text-base text-gray-500">
                <div>
                  <dt className="sr-only">Postal address</dt>
                  <dd>
                    <p>Str. Șelimbărului, nr.32</p>
                    <p>Cisnadie, jud.Sibiu, 555300</p>
                  </dd>
                </div>
                <div className="mt-6">
                  <dt className="sr-only">Telefon</dt>
                  <dd className="flex">
                    <PhoneIcon
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <a
                      href="tel:+40757132016"
                      className="ml-3 hover:text-rose-600 transition-colors"
                    >
                      +40 757 132 016
                    </a>
                  </dd>
                </div>
                <div className="mt-3">
                  <dt className="sr-only">Email</dt>
                  <dd className="flex">
                    <MailIcon
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <a
                      href="mailto: contact@alexbestdrive.ro"
                      className="ml-3 hover:text-rose-600 transition-colors"
                    >
                      contact@alexbestdrive.ro
                    </a>
                  </dd>
                </div>
                <div className="mt-3">
                  <dt className="sr-only">Program</dt>
                  <dd className="flex">
                    <CalendarIcon
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <p className="ml-3">L-V: 8-17 S: 8-13 D: INCHIS</p>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="bg-white py-6 px-4 sm:px-6 lg:col-span-3 lg:py-16 lg:px-8 xl:pl-12">
            {isSubmitted ? (
              <div className="bg-green-50 border border-green-200 rounded-md p-6 text-center shadow-sm">
                <h2 className="text-2xl font-medium text-green-700 mb-4">
                  Mulțumim pentru programare!
                </h2>
                <p className="text-green-600 mb-4">
                  Am primit cererea dumneavoastră! Vă dorim o zi bună!
                </p>
                <button
                  onClick={() => {
                    setIsSubmitted(false);
                    setErrorMessage("");
                  }}
                  className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors duration-200"
                >
                  Încă o programare
                </button>
              </div>
            ) : (
              <form
                ref={formRef}
                onSubmit={handleSubmit}
                className="grid grid-cols-1 gap-y-3 compact-form"
                name="contact"
                method="POST"
                data-netlify="true"
                netlify-honeypot="bot-field"
                netlify
              >
                {/* Hidden field for netlify forms */}
                <input type="hidden" name="form-name" value="contact" />
                <input
                  type="hidden"
                  name="appointmentDate"
                  value={formData.appointmentDate}
                />
                <input
                  type="hidden"
                  name="appointmentTime"
                  value={formData.appointmentTime}
                />

                {/* Honeypot field to prevent spam */}
                <p className="hidden">
                  <label>
                    Don't fill this out if you're human:{" "}
                    <input name="bot-field" />
                  </label>
                </p>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="form-group">
                    <label
                      htmlFor="phone"
                      className="label-with-icon block text-sm font-medium text-gray-700 mb-1"
                    >
                      <PhoneIcon className="h-4 w-4 text-gray-500" />
                      Telefon <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      autoComplete="tel"
                      required
                      maxLength={10}
                      className={`block w-full shadow-sm py-2 px-3 placeholder-gray-400 focus:ring-rose-500 focus:border-rose-500 border-gray-300 rounded-md transition-colors duration-200 ${
                        errorMessage.includes("telefon") ? "border-red-500" : ""
                      }`}
                      placeholder="07XXXXXXXX"
                      onChange={handleChange}
                      value={formData.phone}
                    />
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="name"
                      className="label-with-icon block text-sm font-medium text-gray-700 mb-1"
                    >
                      <UserIcon className="h-4 w-4 text-gray-500" />
                      Nume (opțional)
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      autoComplete="name"
                      maxLength={50}
                      className="block w-full shadow-sm py-2 px-3 placeholder-gray-400 focus:ring-rose-500 focus:border-rose-500 border-gray-300 rounded-md transition-colors duration-200"
                      placeholder="Nume"
                      onChange={handleChange}
                      value={formData.name}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="form-group">
                    <label
                      htmlFor="carNumber"
                      className="label-with-icon block text-sm font-medium text-gray-700 mb-1"
                    >
                      <TruckIcon className="h-4 w-4 text-gray-500" />
                      Număr Înmatriculare{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="carNumber"
                      id="carNumber"
                      required
                      style={{ textTransform: "uppercase" }}
                      maxLength={15}
                      className="block w-full shadow-sm py-2 px-3 placeholder-gray-400 focus:ring-rose-500 focus:border-rose-500 border-gray-300 rounded-md transition-colors duration-200"
                      placeholder="Ex: SB 01 ABC"
                      onChange={handleChange}
                      value={formData.carNumber}
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-2">
                    <div className="form-group">
                      <label
                        htmlFor="appointmentDate"
                        className="label-with-icon block text-sm font-medium text-gray-700 mb-1"
                      >
                        <CalendarIcon className="h-4 w-4 text-gray-500" />
                        Data <span className="text-red-500">*</span>
                      </label>
                      <div className="date-input-container">
                        <input
                          type="date"
                          name="appointmentDate"
                          id="appointmentDate"
                          required
                          min={tomorrowFormatted}
                          max={maxDateFormatted}
                          lang="ro-RO"
                          className={`block w-full shadow-sm py-2 px-3 focus:ring-rose-500 focus:border-rose-500 border-gray-300 rounded-md transition-colors duration-200 ${
                            errorMessage.includes("dată")
                              ? "border-red-500"
                              : ""
                          }`}
                          onChange={handleChange}
                          value={formData.appointmentDate}
                          onClick={(e) => {
                            // Ensure calendar opens on click
                            e.currentTarget.showPicker();
                          }}
                          onInput={(e) => {
                            // All Sunday validation is now handled in handleChange
                          }}
                        />
                      </div>
                      {formData.appointmentDate ? (
                        <p className="mt-1 text-xs text-gray-500">
                          {formatDateRomanian(formData.appointmentDate)}
                        </p>
                      ) : (
                        <p className="mt-1 text-xs text-gray-500">
                          L-V: 8-17, S: 8-13, D: Închis
                        </p>
                      )}
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="appointmentTime"
                        className="label-with-icon block text-sm font-medium text-gray-700 mb-1"
                      >
                        <ClockIcon className="h-4 w-4 text-gray-500" />
                        Ora <span className="text-red-500">*</span>
                      </label>
                      <select
                        name="appointmentTime"
                        id="appointmentTime"
                        required
                        className={`block w-full shadow-sm py-2 px-3 focus:ring-rose-500 focus:border-rose-500 border-gray-300 rounded-md transition-colors duration-200 ${
                          errorMessage.includes("oră") ? "border-red-500" : ""
                        }`}
                        onChange={handleChange}
                        value={formData.appointmentTime}
                        disabled={
                          !formData.appointmentDate ||
                          availableTimes.length === 0
                        }
                      >
                        <option value="">Selectează</option>
                        {availableTimes.map((time) => (
                          <option key={time} value={time}>
                            {time}
                          </option>
                        ))}
                      </select>
                      {formData.appointmentDate &&
                        availableTimes.length === 0 && (
                          <p className="mt-1 text-xs text-red-500">
                            Zi nedisponibilă
                          </p>
                        )}
                    </div>
                  </div>
                </div>

                <div className="form-group mt-1">
                  <label
                    htmlFor="message"
                    className="label-with-icon block text-sm font-medium text-gray-700 mb-1"
                  >
                    <ChatAlt2Icon className="h-4 w-4 text-gray-500" />
                    Serviciu dorit
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows={2}
                    maxLength={300}
                    className="block w-full shadow-sm py-2 px-3 placeholder-gray-400 focus:ring-rose-500 focus:border-rose-500 border-gray-300 rounded-md transition-colors duration-200"
                    placeholder="Am nevoie să schimb anvelope de iarnă..."
                    onChange={handleChange}
                    value={formData.message}
                  />
                  {formData.message && (
                    <p className="mt-1 text-xs text-gray-500 text-right">
                      {formData.message.length}/300
                    </p>
                  )}
                </div>

                {/* Error message */}
                {errorMessage && (
                  <div className="py-2 px-3 bg-red-50 border border-red-200 rounded flex items-start gap-2">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500 flex-shrink-0 mt-0.5" />
                    <p className="text-sm text-red-700">{errorMessage}</p>
                  </div>
                )}

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`mt-2 w-full inline-flex justify-center py-2.5 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-rose-600 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500 transition-colors duration-200 ${
                    isSubmitting ? "opacity-75 cursor-not-allowed" : ""
                  }`}
                >
                  {isSubmitting ? (
                    <>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Se trimite...
                    </>
                  ) : (
                    "Programează-te"
                  )}
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
