import React from "react";
import { Helmet } from "react-helmet";
import { blogPostsPreview } from "./Data/constants";

function Blog() {
  return (
    <div className="relative bg-gray-50 py-16 sm:py-24 lg:py-32 lg:pt-10">
      <Helmet>
        <title>Blog Vulcanizare | Alex Best Drive Cisnadie - Blog</title>
        <link
          rel="canonical"
          href="https://www.vulcanizare-alexbestdrive.ro/blog"
        />
        <meta
          name="description"
          content="Blog cu sfaturi utile despre îngrijirea anvelopelor și a mașinii tale. Vulcanizare Alex Best Drive din Cisnadie"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charSet="UTF-8" />
      </Helmet>
      <div className="relative">
        <div className="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <p className="mt-2 font-extrabold text-gray-900 tracking-tight text-5xl">
            Blog
          </p>
          <p className="mt-5 mx-auto max-w-prose text-2xl text-gray-500">
            Adăgăm periodic sfaturi și metode eficiente prin care să ai grijă de
            mașina ta. Desigur, ținem la timpul tău, deci te asigurăm ca nu îți
            pierdem timpul cu articole generice!
          </p>
        </div>
        <div className="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">
          {blogPostsPreview.map((post) => (
            <div
              key={post.id}
              className="flex flex-col rounded-lg shadow-lg overflow-hidden"
            >
              <a href={post.href}>
                <div className="flex-shrink-0">{post.imageUrl}</div>
              </a>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-cyan-600">
                    <div className="">{post.category.name}</div>
                  </p>
                  <a href={post.href} className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">
                      {post.title}
                    </p>
                    <p className="mt-3 text-base text-gray-500">
                      {post.preview}
                    </p>
                  </a>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <div>
                      <img
                        className="h-10 w-10 rounded-full"
                        src={require("../assets/alexandru-buraciuc.jpg")}
                        alt={post.author.name}
                      />
                    </div>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      <div className="">{post.author.name}</div>
                    </p>
                    <div className="flex space-x-1 text-sm text-gray-500">
                      <time dateTime={post.datetime}>{post.date}</time>
                      <span aria-hidden="true">&middot;</span>
                      <span>{post.readingLength} read</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Blog;
